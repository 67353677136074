<template>
  <div class="operator">
    <el-breadcrumb class="breadCrumb" separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>子公司管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bgWhite mt20">
      <el-table
              :data="tableData"
              style="width: 100%">
        <el-table-column
                type="index"
                label="序号"
                width="80">
        </el-table-column>
        <el-table-column
                prop="companyName"
                label="企业名称"
                width="180">
        </el-table-column>
        <el-table-column
                prop="socCode"
                label="统一信用代码证号">
        </el-table-column>
        <el-table-column
                prop="legalName"
                label="法人姓名">
        </el-table-column>
        <el-table-column
                prop="realName"
                label="经办人姓名">
        </el-table-column>
        <el-table-column
                fixed="right"
                label="操作"
                width="100">
          <template slot-scope="scope">
            <div class="redText">
              <el-button @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="queryInfo.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="mt20">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Subcompany",
    data() {
      return {
        tableData: [],//表格数据
        queryInfo: {//分页
          limit: 10,
          page: 1,

        },
        total: 0,//分页-总数
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //获取表格数据
      async getList() {
        const {data: res} = await this.$auth.get('/cpiaoju-auth/lxgw/front/company/getCoreInfo',{
          params:{
            page:this.queryInfo.page,
            limit:this.queryInfo.limit,
          }
        })
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      },
      handleDetail(row) {
        this.$router.push({path:'/aside/subcompany/detail',query:{id:row.id}})
      },
      //监听 页面条数 改变的事件
      handleSizeChange(newSize) {
        this.queryInfo.limit = newSize;
        // 重新获取列表数据
        this.getList();
      },
      //监听 页码值 改变的事件
      handleCurrentChange(newPage) {
        this.queryInfo.page = newPage;
        // 重新获取列表数据
        this.getList();
      },
    }
  }
</script>

<style lang="less" scoped>
  .bgWhite {
    height: 604px;
  }

  .redText {
    /deep/ .el-button--text {
      color: #C70009;
    }
  }

  /deep/ .el-table thead th {
    background-color: #FAFAFA !important;
  }
</style>